import _ from "lodash";

export const parseCountryData = (countries) => {
  let teamList = [];
  countries.map((rowData, index) => {
    let country = rowData.country;
    teamList[index] = { value: country, label: country, flag: rowData.flag };
  });
  return teamList;
};
// const leagues_list={"Serie A":{country:"Brazil"},"Bundesliga 1":{country:"Germany"},"Primera Division":{country:"Spain"},"Africa Cup of Nations":{country:"World"},"Major League Soccer":{country:"USA"},"Premier League":{country:"England"},"UEFA Champions League":{country:"World"}};
export const parseLeagueData = (leagues) => {
  let mainLeagueNames = [
    { name: "Africa Cup of Nations", country: "World" },
    { name: "English Premier League", country: "England" },
    { name: "La Liga", country: "Spain" },
    { name: "Serie A", country: "Italy" },
    { name: "Bundesliga", country: "Austria" },
    { name: "Ligue 1", country: "France" },
    { name: "Eredivisie", country: "Netherlands" },
    { name: "Primeira Liga", country: "Portugal" },
    { name: "Russian Premier League", country: "Russia" },
    { name: "Argentine Primera División", country: "Argentina" },
    { name: "Brasileirão Serie A", country: "Brazil" },
    { name: "Major League Soccer", country: "USA" },
    { name: "Chinese Super League", country: "China" },
    { name: "K-League 1", country: "South Korea" },
    { name: "J1 League", country: "Japan" },
    { name: "Australian A-League", country: "Australia" },
    { name: "Belgian Pro League", country: "Belgium" },
    { name: "Premiership", country: "Scotland" },
    { name: "Turkish Süper Lig", country: "Turkey" },
    { name: "Swiss Super League", country: "Switzerland" },
    { name: "Liga MX", country: "Mexico" },
    { name: "Portuguese Primeira Divisão", country: "Portugal" },
    { name: "Premier League", country: "Ukraine" },
    { name: "Greek Super League", country: "Greece" },
    { name: "Superliga", country: "Denmark" },
    { name: "Allsvenskan", country: "Sweden" },
    { name: "Eliteserien", country: "Norway" },
    { name: "Ekstraklasa", country: "Poland" },
    { name: "Czech First League", country: "Czech Republic" },
    { name: "Primera Nacional", country: "Argentina" },
    { name: "Primera A", country: "Colombia" },
    { name: "Primera Divisi\u00f3n", country: "Chile" },
    { name: "Uruguayan Primera División", country: "Uruguay" },
    { name: "Paraguayan Primera División", country: "Paraguay" },
    { name: "Peruvian Liga 1", country: "Peru" },
    { name: "Ecuadorian Serie A", country: "Ecuador" },
    { name: "Primera Divisi\u00f3n", country: "Venezuela" },
    { name: "Primera Divisi\u00f3n", country: "Costa Rica" },
    { name: "Saudi Professional League", country: "Saudi Arabia" },
    { name: "South African Premier Division", country: "South Africa" },
    { name: "Premier League", country: "Egypt" },
    { name: "Tunisian Ligue Professionnelle 1", country: "Tunisia" },
    { name: "Algerian Ligue Professionnelle 1", country: "Algeria" },
    { name: "Botola Pro", country: "Morocco" },
    { name: "NPFL", country: "Nigeria" },
    { name: "Premier League", country: "Ghana" },
    { name: "Elite One", country: "Cameroon" },
    { name: "Ligue 1", country: "Ivory Coast" },
    { name: "Ligue 1", country: "Senegal" },
    { name: "FKF Premier League", country: "Kenya" },
    { name: "World Cup", country: "World" },
    { name: "UEFA Nations League", country: "World" },
    { name: "UEFA Champions League", country: "World" },
    { name: "Bundesliga", country: "Germany" },
    { name: "League One", country: "England" },
    { name: "Premier League", country: "England" },
    { name: "UEFA European Championship (Euro)", country: "Europe" },
    { name: "Copa America", country: "South America" },
    { name: "AFC Cup", country: "World" },
    { name: "CONCACAF Gold Cup", country: "World" },
    { name: "CONMEBOL Copa Libertadores", country: "South America" },
    { name: "AFC Champions League", country: "World" },
    { name: "CAF Champions League", country: "World" },
    { name: "CONCACAF Champions League", country: "World" },
    { name: "FIFA Club World Cup", country: "World" },
    { name: "Olympic Football Tournament", country: "International" },
    { name: "FIFA U-20 World Cup", country: "International" },
    { name: "FIFA U-17 World Cup", country: "International" },
    { name: "AFC U23 Asian Cup", country: "Asia" },
    { name: "CONCACAF Nations League", country: "World" },
    { name: "CONMEBOL Sudamericana", country: "World" },
    { name: "CAF Confederation Cup", country: "World" },
    { name: "AFC U19 Championship", country: "Asia" },
    { name: "CAF U20 Africa Cup of Nations", country: "Africa" },
    { name: "CONCACAF U20 Championship", country: "North and Central America" },
    { name: "UEFA U19 Championship", country: "World" },
    { name: "AFC U16 Championship", country: "Asia" },
    { name: "CAF U17 Africa Cup of Nations", country: "Africa" },
    { name: "CONCACAF U17", country: "World" },
    { name: "UEFA U17 Championship", country: "World" },
    { name: "FIFA Beach Soccer World Cup", country: "World" },
    { name: "AFC Futsal Championship", country: "World" },
    { name: "UEFA Futsal Championship", country: "World" },
    { name: "CONCACAF Futsal Championship", country: "World" },
    { name: "CAF Futsal Africa Cup of Nations", country: "World" },
    { name: "AFC Women's Asian Cup", country: "Asia" },
    { name: "UEFA Champions League Women", country: "World" },
    { name: "Copa America Femenina", country: "South America" },
    { name: "CAF Women's Africa Cup of Nations", country: "Africa" },
    { name: "FIFA U20 Women's World Cup", country: "International" },
    { name: "FIFA U17 Women's World Cup", country: "International" },
    { name: "AFC U19 Women's Championship", country: "Asia" },
    { name: "CAF U20 Women's Africa Cup of Nations", country: "Africa" },
    { name: "UEFA U19 Championship - Women", country: "World" },
    { name: "AFC U16 Women's Championship", country: "Asia" },
    { name: "CAF U17 Women's Africa Cup of Nations", country: "Africa" },
    { name: "UEFA U17 Women's Championship", country: "Europe" },
    { name: "FIFA Women's World Cup", country: "International" },
  ];
  const allLeagues = leagues
    .filter(
      (x) =>
        mainLeagueNames.findIndex(
          (y) => y.name === x.name && y.country === x.country
        ) !== -1
    )
    .map((x) => ({
      id: x.league_id,
      value: `${x.name} - ${x.country}`,
      img: x.logo ? x.logo : x.flag,
    }));
  const defaultSelectedLeague = allLeagues.find(
    (league) => league.value === "Premier League - England"
  );
  console.log ("mainLeagueNames", mainLeagueNames);
  console.log ("allLeagues", allLeagues);
  console.log ("defaultSelectedLeague", defaultSelectedLeague);
  return { allLeagues, defaultSelectedLeague };
};

export const parseUserDetails = (userData) => {
  return Object.assign({}, userData, {
    name: userData.name,
    team: {
      label: userData.team,
      value: userData.team,
      flag: userData.teamFlag,
    },
    language: {
      label: userData.language,
      value: userData.language,
    },
  });
};

export const addOrReplace = (arr, key, newData, otherData) => {
  const { predicated_id, isAwayTeamPredicted, isHomeTeamPredicted } = otherData;

  if (isHomeTeamPredicted && predicated_id === newData.predicated_team_one_id) {
    if (newData.predicated_team_two_id === null) {
      _.remove(arr, key);
    } else {
      let index = _.findIndex(arr, key);
      if (index >= 0) {
        arr[index].predicated_team_one_id = null;
      }
    }
  } else if (
    isAwayTeamPredicted &&
    predicated_id === newData.predicated_team_two_id
  ) {
    if (newData.predicated_team_one_id === null) {
      _.remove(arr, key);
    } else {
      let index = _.findIndex(arr, key);
      if (index >= 0) {
        arr[index].predicated_team_two_id = null;
      }
    }
  } else {
    let index = _.findIndex(arr, key);
    if (index >= 0) {
      arr.splice(index, 1, newData);
    } else {
      arr.push(newData);
    }
  }
  return arr;
};

export const teamShortHandName = (teamName) => {
  let newName;
  newName = teamName && teamName.substring(0, 3).toUpperCase();
  return newName;
};
export const playerShortHandName = (playerName) => {
  let playerShortName = playerName.split(" ");

  let shortPlayerName =
    playerShortName.length >= 2
      ? `${playerShortName[0]} ${playerShortName[1]}`
      : playerName;
  return shortPlayerName;
};
